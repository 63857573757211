import React from "react";
import './Home.css';
import sankalpIcon from "../../assets/companyIcon.png";
import { Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const HomeBase = ({scrollToContact}) => {

    // const navigate = useNavigate();

    // Function to handle navigation
    // ass


  return (
    <div className="home-container">

      <div className="content">
                <img src={sankalpIcon} alt="company logo" className="company-logo1"></img>
                <div className="heading"> Sankalp Research Foundation 
        </div>
        <p className="description">At Sankalp Research Foundation, we empower defense, government, and security organizations with the intelligence needed for critical decisions. Our expert solutions blend advanced analytics, human-machine collaboration, and defense insights to provide actionable, open-source intelligence on military capabilities, global events, and market trends.
        </p>
        <Button variant="contained" className="ctaBtn" onClick={scrollToContact}>Connect With Us Today</Button>
      </div>
    </div>
  )
}

export default HomeBase;