import React from "react";
import './WhatWeDo.css';
import whatWeDoVisual from "../../assets/WhatWeDoImg.png"

const WhatWeDo = () => {
  return (
    <div className="whatWeDo-container">
      <div className="whatWeDo-content">
      <div className="whatWeDo-heading">
      What We Do
      </div>
      <div className="whatWeDo-description">
      At Sankalp we bring together millions of verified data points to create a fully interconnected intelligence framework. This allows our clients to:
      </div>
      </div>
        <img src={whatWeDoVisual} alt="what we do explained" className="whatWeDo-image"></img>
    </div>
  );
}

export default WhatWeDo;
