import React from "react";
import './ContactUs.css';
import contactUsVisual from "../../assets/contactUs.png"

const ContactUs = ({contactRef}) => {
  return (
    <div className="contactUs-container" ref={contactRef}>
      <div className="contactUs-content">
      <div className="contactUs-heading">
      Let Us Help You
      </div>
      <div className="contactUs-description">
      Whether you're planning strategic military operations, tracking geopolitical shifts, or navigating defense markets, Sankalp Research Foundation is here to provide the intelligence you need to make the right decisions.
      </div>
      </div>
        <img src={contactUsVisual} alt="contact explained" className="contactUs-image"></img>
        <div className="contactUs-description2"><b>Get in touch with us today</b> to learn more about how we can support your mission.
        </div>
    </div>
  );
}

export default ContactUs;
