import React from "react";
import './ChooseUs.css';
import chooseUsVisual from "../../assets/WhyChooseUsImg.png"
import chooseUsVisualMob from "../../assets/WhyChooseUsImgMob.png"

const ChooseUs = () => {
  return (
    <div className="chooseUs-container">
      <div className="chooseUs-content">
      <div className="chooseUs-heading">
      Our Comprehensive Approach Ensures 
      <br></br>Every Aspect is Covered
      </div>
      <div className="chooseUs-description">
      By combining expert tradecraft with innovative human-machine teaming, we create a platform that integrates all relevant data into a single, seamless intelligence environment. This enables faster analysis, more accurate decision-making, and optimized use of resources.
      </div>
      </div>
      <img src={chooseUsVisual} alt="Why Choose Us explained" className="chooseUs-image"></img>   
      <img src={chooseUsVisualMob} alt="Why Choose Us explained" className="chooseUs-image-mob"></img>       
    </div>
  );
}

export default ChooseUs;
