import React from "react";
import './Overview.css';
import overviewVisual from "../../assets/missionImg.png"

const Overview = () => {
  return (
    <div className="overview-container">
      <div className="overview-content">
      <div className="overview-heading">
      Our Mission
      </div>
      <div className="overview-description">
      We aim to provide cutting-edge defence intelligence that enables our clients to stay ahead in an ever-changing world. By offering a complete and accurate understanding of military assets, geopolitical landscapes, and strategic opportunities, we help our customers make informed decisions that can shape the future.
      </div>
      </div>
        <img src={overviewVisual} alt="overview explained" className="overview-image"></img>
    </div>
  );
}

export default Overview;
