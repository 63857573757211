import React, { useRef } from "react"
import Homebase from "../components/Home/Home";
import Overview from "../components/Home/Overview";
import Footer from "../components/Footer/Footer";
import WhatWeDo from "../components/Home/WhatWeDo";
import ChooseUs from "../components/Home/ChooseUs";
import ContactUs from "../components/Home/ContactUs";
const Home = () => {
    const contactRef = useRef(null);

    const scrollToContact = () => {
        contactRef.current?.scrollIntoView({behavior: "smooth"});
    }

    return(
        <>
        <Homebase scrollToContact={scrollToContact} />
        <Overview />
        <WhatWeDo />
        <ChooseUs />
        <ContactUs contactRef={contactRef} />
        <Footer />
        </>
    )
}
export default Home;