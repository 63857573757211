import React from "react";
import { Link } from "react-router-dom"; // Make sure you have react-router-dom installed
import './Footer.css'; // Ensure the CSS file is in the same directory as this component
import sankalpLogo from "../../assets/sankalp-logo.png";

const Footer = () => {
  return (
    <footer className="footer-container">
        <div className="company-content">
            <img src={sankalpLogo} alt="company logo" className="company-logo-footer"></img>
            <div className="slogan">Empowering Defence and Security with Precision-Driven Intelligence.</div>
        </div>
      <div className="copyright-footer">All rights reserved © 2024 SankalpFoundation</div>
    </footer>
  );
};

export default Footer;
